/**
 * 水光生模块 常量
 */

export const LEVEL_OF = {
  ZC: {
    name: '总裁', // 显示字符串
    code: 'ZC', // 实际字符串
    backendPrefix: 'director', // 后端接口前缀
    route: 'director-sum', // 对应的业绩首页
    recommendPartnerRoute: 'group-purchase', // 推荐合伙人页面
  },
  FZC: {
    name: '副总裁',
    code: 'FZC',
    backendPrefix: 'director',
    route: 'director-sum',
    recommendPartnerRoute: 'group-purchase', // 推荐合伙人页面
  },
  DS: {
    name: '董事',
    code: 'DS',
    backendPrefix: 'director',
    route: 'director-sum',
    recommendPartnerRoute: 'group-purchase', // 推荐合伙人页面
  },

  ZLHHR: {
    name: '战略合伙人',
    code: 'ZLHHR',
    backendPrefix: 'strategy',
    route: 'partner-sum',
    recommendPartnerRoute: 'recommend-partner', // 推荐合伙人页面
  },

  QGZDL: {
    name: '全国总代理',
    code: 'QGZDL',
    backendPrefix: 'sole-agent',
    route: '',
    recommendPartnerRoute: '', // 推荐合伙人页面
  },
  JXS: {
    name: '经销商',
    code: 'JXS',
    backendPrefix: 'dealer',
    route: '',
    recommendPartnerRoute: '', // 推荐合伙人页面
  },
  //  新增千漾公司 todo
  GS: {
    name: '千漾公司',
    code: 'GS',
    route: '',
  },
}

// 比较等级
export function compareLevel (a, b) {
  const level = {
    [LEVEL_OF.ZC.code]: 1,
    [LEVEL_OF.FZC.code]: 1,
    [LEVEL_OF.DS.code]: 1,
    [LEVEL_OF.ZLHHR.code]: 2,
    [LEVEL_OF.QGZDL.code]: 3,
    [LEVEL_OF.JXS.code]: 3,
    [LEVEL_OF.GS.code]: 1    // 千漾公司，code也给了1
  }
  if (level[a] < level[b]) return 1
  else if (level[a] > level[b]) return -1
  else return 0
}

/**
 * 获取镜像页面，比如：
 * - 董事业绩首页 <=> 战略业绩首页
 * - 董事团队达标 <=> 战略团队达标
 * @param {string} curRoute 当前页面
 * @param {string} newLevel 新等级
 * @returns {string} 镜像页面
 */
export function getMirrorRoute (curRoute, newLevel) {
  const grid = [
    ['director-sum', 'partner-sum'],
    ['group-purchase', 'recommend-partner'],
    ['team-standard', 'team-standard'],
    ['discount-reward', 'discount-reward']
  ]

  const row = {
    'director-sum': 0,
    'partner-sum': 0,
    'group-purchase': 1,
    'recommend-partner': 1,
    'team-standard': 2,
    'discount-reward': 3
  }

  const column = {
    [LEVEL_OF.ZC.code]: 0,
    [LEVEL_OF.FZC.code]: 0,
    [LEVEL_OF.DS.code]: 0,
    [LEVEL_OF.ZLHHR.code]: 1,
    [LEVEL_OF.QGZDL.code]: 2,
    [LEVEL_OF.JXS.code]: 2,
  }

  if (!row.hasOwnProperty(curRoute)) {
    return curRoute
  }

  return grid[row[curRoute]][column[newLevel]]
}

//授权模块原有的常量 即产品是水光生
export const SYSTEM_ID = '5017c1d0-9bde-11e9-9527-4591eee642d6'

//战略合伙人的等级 FIXME 修改为非写死常量
export const ZLHHR_LEVEL = 4;
