import schema from 'async-validator';
import { Dialog, Notify, Toast } from 'vant';
import { mapState } from 'vuex';
import { SYSTEM_ID, ZLHHR_LEVEL } from '@/constants/sgs.js';
import UploadImage from '@/components/common/upload-image/index.vue';
import { addAgentFromSenior, addAgentFromJunior, getLevels, getAgentDetail } from '@/service/auth/index';
import { descriptor, descriptor2 } from './descriptor';
import PersonalInfo from '@/components/common/personal-info/index.vue';
import UploadIdentification from '@/components/upload-identification/index.vue';
import IDValidator from 'id-validator';
/**
 * 增加代理
 */
export default {
    name: 'add-agent',

    components: {
        UploadImage,
        PersonalInfo,
        UploadIdentification,
    },

    computed: {
        cannotAdd() {
            return !this.levelList || this.levelList.length <= 0;
        },
        ...mapState({
            userId: state => state.user.user.id,
            userPhone: state => state.user.user.phone,
            // 是否上传凭证
            receipt: state =>
                state.permission.permission.auth['increment-apply'] &&
                state.permission.permission.auth['increment-apply']['receipt'],
            showReferrer: state =>
                state.permission.permission.auth['increment-apply'] &&
                state.permission.permission.auth['increment-apply']['referrer'],
            showIdentityCode: state =>
                state.permission.permission.auth['increment-apply'] &&
                state.permission.permission.auth['increment-apply']['identity-code'],
            showIdentityPhoto: state =>
                state.permission.permission.auth['increment-apply'] &&
                state.permission.permission.auth['increment-apply']['identity-photo'],
        }),
    },

    data() {
        return {
            seniorInfo: null, //上级代理的信息
            isself: true,
            labelMap: {},
            //  身份证信息
            idfImages: {
                back: null,
                font: null,
            },
            levelList: [],
            systemList: [], //真实的，可以使用的列表
            showProductPicker: false,
            showLevelPicker: false,
            //表单数据
            addAgentForm: {
                productObj: {},
                level: '',
                phone: '',
                referrerName: '',
                money: '',
                images: [],
            },
            SYSTEM_ID,
            //赋值的逻辑： const systemId = this.product.selectList[i].id,由于目前只有水光生，所以SYSTEM_ID = systemId,
            systemId: SYSTEM_ID,
            systemName: '水光生',
            needsHide: {}, // 需要隐藏的
            showVoucher: true, // 显示上传凭证项（证件照/汇款截图）
        };
    },

    methods: {
        onProductPickerConfirm(value, index) {
            this.addAgentForm.product = value.name;
            this.addAgentForm.productObj = value;
            this.showProductPicker = false;
        },

        onLevelPickerConfirm(value, index) {
            // 切换品牌隐藏相应项（等级包括白皙等级和实力瘦等级）
            if (value[1] == '团购长' || value[1] == '铂金VIP' || value[1] == '钻石VIP' || value[1] == 'VIP') {
                this.needsHide = {
                    identity: true,
                    wechat: true,
                    region: true,
                    profession: true,
                    identityType: true,
                    education: true,
                };
                this.showVoucher = false;
            } else {
                this.needsHide = {};
                this.showVoucher = true;
            }

            //品牌名，授权成功后要显示
            this.systemName = value[0] || '水光生';
            this.showLevelPicker = false;
            this.addAgentForm.level = value[0] + '-' + value[1];
            this.addAgentForm.levelObj = this.systemList[this.levelList[0].index]['levels'].find(level => {
                return level.name == value[1];
            });
        },

        /**
         * 校验表单
         * 校验通过：返回true，否则返回false
         */
        validateForm() {
            let validators = new schema(this.showVoucher ? descriptor : descriptor2);
            // 添加校验规则
            if (showIdentityPhoto) {
                // descriptor
                this.showVoucher ? descriptor : descriptor2;
            }
            let pass = true;
            const { addAgentForm, showIdentityPhoto, idfImages } = this;
            const personalInfo = this.$refs['personalInfo'].getFormData();
            // 后面非水光生产品的时候，可以在descriptor.js中export非水光生产品需要的descriptor
            // 注意两个表单字段不能重名
            if (idfImages) {
                addAgentForm.identityFrontMediaId = idfImages.font && idfImages.font.serverId;
                addAgentForm.identityBackMediaId = idfImages.back && idfImages.back.serverId;
            }
            const fullForm = {
                ...addAgentForm,
                ...personalInfo,
            };

            validators.validate(fullForm, (errors, fields) => {
                if (errors && errors.length) {
                    this.$toast(errors[0].message);
                    pass = false;
                }
            });
            // 校验没问题的话在做证件号码的校验
            if (pass && this.showVoucher) {
                const idValidator = new IDValidator();
                // TODO 当类型是身份证的时候，前端校验是否正确的身份证，这个是不对，后面要改动
                if (!fullForm.identity) {
                    this.$toast('请输入您的证件号码');
                    pass = false;
                } else if (fullForm.identity && fullForm.identityTypeCode == 0) {
                    if (!idValidator.isValid(fullForm.identity)) {
                        this.$toast('申请人身份证输入不合法');
                        pass = false;
                    }
                }
            }
            return pass;
        },

        /**
         * 新增成功
         */
        addAgentSuccess() {
            const { brandId } = this.$route.query;
            const { levelObj, phone } = this.addAgentForm;
            const { name } = this.$refs['personalInfo'].getFormData();
            let underStrategy = false;

            if (levelObj.rank > ZLHHR_LEVEL) {
                underStrategy = true;
            }

            this.$router.replace({
                name: 'add-agent-result',
                query: {
                    phone,
                    brandName: this.systemName,
                    brandId,
                    name,
                    underStrategy,
                },
            });
        },
        // 获取上级的信息
        async getSeniorInfo() {
            const { seniorId } = this;
            this.seniorInfo = await getAgentDetail(seniorId);
        },
        /**
         * 提示是否继续提交表单,确认后发请求
         */
        showSureWindow() {
            const {
                phone,
                level,
                money,
                images,
                levelObj,
                identityBackMediaId,
                identityFrontMediaId,
            } = this.addAgentForm;
            const {
                name,
                identity,
                wechat,
                gender,
                regionId,
                identityTypeName,
                identityTypeCode,
                professionId,
                educationId,
            } = this.$refs['personalInfo'].getFormData();
            const mediaIds = images.map(image => image.serverId);
            const levelId = levelObj.id;

            const fields = [
                ['新增代理姓名', name],
                ['新增代理手机号', phone],
                ['品牌等级', level],
            ];
            const message = fields.reduce((acc, pair, index, array) => {
                const [name, value] = pair;
                acc += name + '：' + value;
                if (index !== array.length - 1) acc += '\n';
                return acc;
            }, '');
            Dialog.confirm({
                title: '新增代理信息确认',
                messageAlign: 'left',
                message,
            }).then(() => {
                // 如果是下级代理自己添加的话
                // 默认穿身份证，青葱不需要选择类型，所以默认设置参数为身份证
                if (this.seniorId) {
                    addAgentFromJunior({
                        levelId,
                        seniorId: this.isself ? this.userId : this.seniorId,
                        phone,
                        name,
                        mediaIds: this.receipt ? mediaIds : undefined,
                        money,
                        identityTypeCode: identityTypeCode || 0,
                        identityTypeName: identityTypeName || '身份证',
                        identity,
                        wechat,
                        gender,
                        regionId,
                        professionId,
                        educationId,
                        identityFrontMediaId,
                        identityBackMediaId,
                    }).then(() => {
                        this.addAgentSuccess();
                    });
                } else {
                    addAgentFromSenior({
                        levelId,
                        seniorId: this.isself ? this.userId : this.seniorId,
                        phone,
                        name,
                        mediaIds,
                        money,
                        identity,
                        identityTypeCode: identityTypeCode || 0,
                        identityTypeName: identityTypeName || '身份证',
                        wechat,
                        gender,
                        regionId,
                        professionId,
                        educationId,
                        identityFrontMediaId,
                        identityBackMediaId,
                    }).then(() => {
                        this.addAgentSuccess();
                    });
                }
            });
        },

        /**
         * 提示没有新增代理的权限
         */
        showNoPermissionsWindow() {
            Dialog.confirm({
                title: '错误提示',
                message: `您的级别已为最低级别，无法新增代理！`,
            });
        },

        onSubmit() {
            //如果没有新增权限，提示
            const { cannotAdd } = this;
            if (cannotAdd) {
                this.showNoPermissionsWindow();
                return;
            }
            const isOk = this.validateForm();
            if (isOk) {
                this.showSureWindow();
            }
        },

        // 获取可以使用的升级等级
        async getLevelList() {
            let { userId, seniorId } = this;
            // 如果带了seniorId,则需要使用这个id来代替userId。据说是 查询上级等级，可询问后台
            if (seniorId) {
                userId = this.seniorId;
            }
            const { systems } = await getLevels(userId);
            // 改装数据为vant-popu使用
            const showSystemList = [
                { values: [], index: 0, className: 'column1', key: '产品', defaultIndex: 0 },
                { values: [], key: '等级', className: 'column2', defaultIndex: 0 },
            ];
            this.systemList = systems;
            systems.forEach(system => {
                showSystemList[0].values.push(system.name);
            });
            //  只放第1个的进去，后面动态的去设置第二个的

            systems &&
                systems[0].levels &&
                systems[0].levels.forEach(level => {
                    showSystemList[1].values.push(level.name);
                });
            this.levelList = showSystemList;

            if (!systems || systems.length <= 0) {
                this.showNoPermissionsWindow();
            }
        },
        //切换用户选择的品牌
        systemChange(target, value) {
            // 动态的去设置下
            const secondList = this.systemList.find((element, index) => {
                this.levelList[0].index = index; //吧下标记录下来，后面就不用在遍历了
                return element.name == value[0];
            });
            const secondArray = [];
            secondList.levels.forEach(element => {
                secondArray.push(element.name);
            });
            target.setColumnValues(1, secondArray);
        },
        //根据实际情况增加对某些字段的校验
        adddescriptor() {
            // 证件图片要求
            if (this.showIdentityPhoto && this.showVoucher) {
                Object.assign(descriptor, {
                    identityFrontMediaId: {
                        required: true,
                        message: '申请人证件正面照不能为空',
                    },
                    identityBackMediaId: {
                        required: true,
                        message: '申请人证件背面照不能为空',
                    },
                });
            }

            // 上传凭证
            if (this.receipt && this.showVoucher) {
                Object.assign(descriptor, {
                    images: [
                        {
                            type: 'array',
                            required: true,
                            message: '至少上传一张转账/汇款截图',
                        },
                    ],
                });
            }
        },
    },

    mounted() {
        this.adddescriptor();
        // 动态的刷新文字
        const { seniorId } = this.$route.query;
        if (seniorId) {
            this.labelMap = {
                name: '您的姓名',
                gender: '您的性别',
                phone: '您的手机号码',
                wechat: '您的微信号',
                region: '您的所在地',
                profession: '您的职业',
                education: '您的学历',
            };
            this.addAgentForm.phone = this.userPhone;
            if (this.showIdentityCode) {
                this.labelMap.identityType = '证件类型';
                this.labelMap.identity = '您的身份证号码';
                if (this.showIdentityPhoto) {
                    this.labelMap.identity = '您的证件号码';
                }
            }
            this.isself = false;
            this.seniorId = seniorId;
            this.getSeniorInfo();
        } else {
            this.labelMap = {
                name: '代理姓名',
                gender: '代理性别',
                phone: '代理手机号码',
                wechat: '代理微信号',
                region: '代理所在地',
                profession: '代理职业',
                education: '代理学历',
            };
            if (this.showIdentityCode) {
                this.labelMap.identityType = '证件类型';
                this.labelMap.identity = '代理身份证号码';
                if (this.showIdentityPhoto) {
                    this.labelMap.identity = '代理证件号码';
                }
            }
            this.isself = true;
        }
        this.getLevelList();
    },
};
