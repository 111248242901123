/**
 * 我的代理
 */
import { Dialog } from 'vant';
import { mapState } from 'vuex';

import IndexTabbar from '@/components/common/index-tabbar/index.vue';
import AgentSearch from '@/components/auth/agent-search/index.vue';
import ContactService from '@/components/auth/contact-service/index.vue';
import EmptyList from '@/components/common/empty-list/index.vue';
import Tabs from '@/components/other/tabs/index.vue';
import InvitationHeader from '@/components/auth/invitation-header/index.vue';
import { getStartedAt, getEndedAt, getDate } from '@/utils/common';

import * as Service from '@/service/auth';
import { ISSHILISHOU } from '@/utils/common.js';

export default {
    name: 'add-invitation',

    components: {
        IndexTabbar,
        AgentSearch,
        ContactService,
        EmptyList,
        Tabs,
        InvitationHeader,
    },

    data() {
        return {
            refferAllJuniors: [], //我推荐的人的列表
            keyword: '', // 搜索关键字
            directReferrals: {}, //  直接推荐人，key为拼音首字母，value为对应字母开头的推荐人
            indirectReferrals: {}, // 结构同directReferrals，是按照直属下级查看
            levels: [], // 按等级查看
            system: [], //按照产品
            isSearchMode: false, // 搜索模式
            tabList: [
                { name: '直接推荐(0)', index: 0 },
                { name: '间接推荐(0)', index: 1 },
            ],
            selectedDate: new Date(),
            userInfo: {
                id: '0001',
                name: '吴亦凡',
                headImg: 'https://tse1-mm.cn.bing.net/th/id/OIP.9finJW2__HweWnazL7vX1QAAAA?w=181&h=181&c=7&o=5&pid=1.7',
                levelName: '股东',
                level: 2,
            },
            textTitle: '我的推荐团队总人数',
            total: 0,
            isUpgrade: false,
            tabItemKey: 0,
        };
    },

    computed: {
        ...mapState({
            user: state => state.user.user,
            brandId: status => status.global.brandId,
        }),

        // 是否展示我的团体
        showMyTeam() {
            return ISSHILISHOU();
        },

        // 下级经销商列表
        agents() {
            let agents = [];
            const { tabItemKey, directReferrals, indirectReferrals } = this;
            if (tabItemKey === 0) {
                if (directReferrals) {
                    const values = Object.values(directReferrals);
                    values.forEach(list => {
                        if (list && list.length) {
                            agents = agents.concat(list);
                        }
                    });
                }
            }
            if (tabItemKey === 1) {
                if (indirectReferrals) {
                    const values = Object.values(indirectReferrals);
                    values.forEach(list => {
                        if (list && list.length) {
                            agents = agents.concat(list);
                        }
                    });
                }
            }
            return agents;
        },
    },

    watch: {
        $route: {
            handler: function(to, from) {
                // let { type } = this.query;
                // if (type == 0) {
                //   this.textTitle = '我的推荐团队总人数';
                //   this.tabList = [
                //     { name: "直接推荐(0)", index: 0 },
                //     { name: "间接推荐(0)", index: 1 },
                //   ];
                // } else {
                //   this.textTitle = '本月升级代理';
                //   this.tabList = [
                //     { name: "按昵称查看(0)", index: 0 },
                //     { name: "按等级查看", index: 1 },
                //   ];
                // }
            },
            // 深度观察监听
            deep: true,
        },

        //  监听日期的变化
        selectedDate(newValue, oldValue) {
            if (newValue.getTime() !== oldValue.getTime()) {
                this.init(); // 重新请求
            }
        },
    },

    methods: {
        // 获取推荐人的信息
        async getseniorInfo() {
            const seniorInfo = await Service.getseniorInfo(this.user.id, this.brandId);
            // 推荐人为空白的时候不显示
            seniorInfo.length ? (this.agents = seniorInfo) : (this.agents = []);
            console.log(this.agents);
        },
        onDetail(agent) {
            // 如果是推荐人的话，不跳转页面
            if (this.user.valid != 1) {
                return;
            }
            this.$router.push({
                name: 'agent-detail',
                query: {
                    agentId: agent.id,
                    type: agent.type,
                    systemId: agent.systemId,
                },
            });
        },

        // 清空关键字
        onDeleteKeyword() {
            this.keyword = '';
            this.$refs['keyword'].focus();
        },

        // 取消：取消搜索模式
        onCancel() {
            this.keyword = '';
            this.isSearchMode = false;
        },

        // 点击输入：进入搜索模式
        onFocus() {
            this.isSearchMode = true;
        },

        // 新增代理：跳转
        onAdd() {
            this.$router.push({
                name: 'add-agent',
            });
        },
        // 跳转到我的申请
        myApplication() {
            this.$router.push({
                name: 'my-application',
            });
        },
        // 二维码邀请
        agentInvite() {
            this.$router.push({
                name: 'agent-invite',
            });
        },
        // 升级代理：先搜索代理
        onUpgrade() {
            this.$refs['keyword'].focus();
        },

        async init() {
            const { brandId, user, $route, selectedDate } = this;
            const { type } = $route.query;
            const { id: userId } = user;
            let data = {};
            const startTime = getStartedAt(selectedDate);
            const endTime = getEndedAt(selectedDate);
            if (type == 0) {
                data = await Service.getNewAddAgency({
                    systemId: brandId,
                    userId: userId,
                    queryUserId: userId,
                    startTime: startTime,
                    endTime: endTime,
                });
            } else {
                data = await Service.getNewUpgradeAgency({
                    systemId: brandId,
                    userId: userId,
                    queryUserId: userId,
                    startTime: startTime,
                    endTime: endTime,
                });
            }
            if (this.user.valid === 1 && data) {
                if (type == 0) {
                    this.directReferrals = data.directList;
                    this.indirectReferrals = data.indirectList;
                    this.total = data.sum;
                } else {
                    this.directReferrals = data.upgradeList;
                    this.levels = data.levelList;
                    this.total = data.sum;
                }
            } else {
                this.getSeniorInfo();
            }
        },

        // 跳转到团队列表
        handleSwitch() {
            this.$router.push({
                name: 'my-agents',
            });
        },

        // tab 导航栏点击
        handleTabNavClick(key) {
            this.tabItemKey = key;
        },
    },

    async created() {
        const { user } = this;
        const { id, name, avatar, levelall } = { ...user };
        this.userInfo = { id: id, name: name, headImg: avatar, levelName: levelall[0].name };
        const { type } = this.$route.query;
        if (type == 0) {
            this.textTitle = '我的推荐团队总人数';
            this.tabList = [
                { name: '直接推荐(0)', index: 0 },
                { name: '间接推荐(0)', index: 1 },
            ];
            this.isUpgrade = false;
        } else {
            this.textTitle = '本月升级代理';
            this.tabList = [
                { name: '按昵称查看(0)', index: 0 },
                { name: '按等级查看', index: 2 },
            ];
            this.isUpgrade = true;
        }
        this.init();
    },
};
