<template>
  <div class="operator-info">
    <img class="success_icon" src="@/assets/images/zpcommon/success.png" />
    <slot name="info">
      <div class="info">
        <p class="big">申请已提交成功！</p>
        <p>请耐心等待客服的审核</p>
      </div>
    </slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less">
@import "./../../../../assets/less/theme.less";
.operator-info {
  .success_icon {
    width: 92px;
    margin-top: 68px;
    height: 92px;
  }
  padding-top: 48px;
  font-size: 15px;
  font-family: PingFangSC-Medium;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #ff5656;

  .info {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: bold;
    line-height: 21px;
    color: @primary-color;
    opacity: 1;

    padding-top: 24px;
  }

  .van-icon {
    font-size: 64px;
    color: #ff5656;
  }
}
</style>

