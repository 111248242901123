import { mapState } from 'vuex';
import { Dialog, Toast } from 'vant';
import { TabList } from './config';

export default {
    data() {
        return {};
    },

    computed: {
        ...mapState({
            params: state => state.params.paramsList,
        }),

        tabList() {
            const { params } = this;
            params.map(param => {
                if (param.value == 0) {
                    TabList.map(d => {
                        if (param.name === d.name) {
                            d.to = '';
                            d.errormsg = '该功能暂未开放~';
                        }
                    });
                }
            });
            let data = [...TabList];
            return data;
        },
    },

    mounted() {},

    methods: {
        // 事件分发
        async handleAction(item) {
            // 判断是否已经在管做了限制不显示
            if (item.errormsg) {
                Toast(item.errormsg);
                return;
            }
            if (!item.to) {
                return;
            }
            let routeName = item.to;

            // 如果是二维码的话需要校验下可否邀请
            if (routeName == 'agent-invite') {
                if (!(await Service.judgeAuth())) {
                    Dialog.alert({
                        message: '您还没有授权，不能邀请下级',
                    });
                    return false;
                }
            }
            this.$router.push({
                name: routeName,
                query:
                    routeName === 'upgrade-agent'
                        ? {
                              id: this.user.id,
                              name: this.user.name,
                              // systemId: this.user.systemId,
                              phone: this.user.phone,
                          }
                        : null,
            });
        },
    },
};
