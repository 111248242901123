import { mapState } from 'vuex';
import OperatorInfo from './operatorInfo.vue';

/**
 * 增加代理
 */
export default {
    name: 'add-agent',

    components: {
        OperatorInfo,
    },

    data() {
        return {
            isUpgrade: false,
            name: '',
            brandName: '',
            underStrategy: true,
        };
    },

    // 监听,当路由发生变化的时候执行
    watch: {
        $route: {
            handler: function(val, oldVal) {
                this.getParams(val);
            },
            // 深度观察监听
            deep: true,
        },
    },

    methods: {
        onClickBackHome() {
            const homePage = this.$store.state.permission.permission.app['home-page'];
            this.$router.replace({
                name: homePage || 'product-list',
            });
        },

        onclickContinue() {
            this.$router.replace({
                name: 'my-agents',
            });
        },

        getParams($route) {
            const { name: routeName, query } = $route;
            if (routeName === 'upgrade-agent-result') {
                this.isUpgrade = true;
            }
            const { name, brandName, phone, underStrategy } = query;
            this.name = name;
            this.phone = phone || '';
            this.brandName = brandName;
            this.underStrategy = underStrategy === true || underStrategy === 'true' ? true : false;
        },
    },

    created() {
        this.getParams(this.$route);
    },
};
