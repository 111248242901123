/**
 * 代理搜索
 */
import { mapState } from 'vuex';

export default {
    name: 'agent-search',

    props: {
        agents: {
            // 代理列表
            type: Array,
            default: () => [],
        },
        keyword: {
            // 关键字
            type: String,
            default: '',
        },
    },

    computed: {
        ...mapState({
            user: state => state.user.user,
        }),
        realKeyword() {
            return this.keyword.trim();
        },

        // 过滤后的代理列表
        showAgents() {
            const keyword = this.realKeyword;
            return this.agents
                .map(agent => {
                    const text = `${agent.name}（${agent.phone}）`;
                    const highlightBegin = text.indexOf(keyword);
                    return {
                        ...agent,
                        text,
                        highlightBegin,
                        highlightEnd: highlightBegin + (keyword ? keyword.length : text.length),
                    };
                })
                .filter(item => item.highlightBegin !== -1);
        },
    },

    methods: {
        onDetail(agent) {
            // 如果是valid为0，则说明没有权限去查看
            if (!this.user.valid || this.user.valid === 0) {
                return false;
            }
            this.$router.push({
                name: 'agent-detail',
                query: {
                    agentId: agent.id,
                    type: agent.type,
                    systemId: agent.systemId,
                },
            });
        },
    },
};
