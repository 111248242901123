import { mapState } from 'vuex';
import { Dialog, Toast } from 'vant';
import { getUser, authMystatus } from '@/service/auth';
import { SHILISHOU_LEVEL_MAP } from '@/constants/rebate';
import { upgradeMyself } from '@/service/auth/index';

export default {
    data() {
        return {
            userInfo: {},
            authList: [],
            toUpgradeInfo: {}, //将要升级的品牌id
        };
    },

    computed: {
        ...mapState({
            userId: state => state.user.user.id, // 我的ID
        }),
    },

    created() {
        this.getUserObj();
        this.getAuthList();
    },

    methods: {
        // 获取用户信息
        async getUserObj() {
            const res = await getUser(this.userId);
            this.userInfo = res;
        },
        async getAuthList() {
            const res = await authMystatus({ userId: this.userId });
            this.authList = res;
        },

        // 查看团队
        onTeam() {
            this.$router.push({ name: 'my-agents' });
        },

        // 确认升级到上一级，只能向上升一级
        upgradeByStep(item) {
            // const upLevelId = String(item.levelId - 1);
            // const upLevelName = SHILISHOU_LEVEL_MAP[upLevelId];
            // if (!upLevelName) {
            //     Toast('暂无可升级的等级～');
            //     return;
            // }
            // this.toUpgradeInfo = { ...item, upLevelId, upLevelName };
            this.toUpgradeInfo = { ...item };
            Dialog.confirm({
                title: '确认升级',
                // message: `确认从${item.levelName}升级到${upLevelName}吗？`,
                message: `确认要升级吗？`,
                beforeClose: this.confirmUpgradeBeforeClose,
            });
        },

        // 确认升级
        confirmUpgradeBeforeClose(action, done) {
            const {
                toUpgradeInfo: { levelId, systemId },
            } = this;
            if (action === 'confirm') {
                upgradeMyself({
                    juniorId: this.userId,
                    fromLevelId: levelId,
                    systemId: systemId,
                })
                    .then(e => {
                        const { upgradeId } = e;
                        done();
                        this.$router.replace({
                            name: 'upgrade-agent-result',
                            query: {
                                upgradeId,
                                from: 'agent-home',
                            },
                        });
                    })
                    .catch(e => {
                        done();
                        console.error(e);
                    });
            } else {
                done();
            }
        },
    },
};
