import { pick } from 'lodash'


// 新增代理：校验规则
export const descriptor = {

  level: {
    required: true,
    message: '授权等级不能为空'
  },
  name: {
    required: true,
    message: '申请人姓名不能为空',
    transform(value) {
      return value.trim()
    }
  },
  phone: [
    {
      required: true,
      message: '申请人手机号不能为空'
    },
    // {
    //   pattern: /^1[345789]\d{9}$/,
    //   message: '申请人手机号输入格式错误'
    // }
  ],

  wechat: [
    {
      // required: true,
      message: '申请人微信号不能为空',
      // transform(value) {
      //   return value.trim()
      // }
    },
    {
      pattern: /^(\w|-){5,40}$/,
      message: '申请人微信号格式错误'
    }
  ],
  gender: {
    required: true,
    message: '申请人性别不能为空'
  },
  regionId: {
    required: true,
    message: '申请人省市区不能为空'
  },
  professionId: {
    required: true,
    message: '申请人职业不能为空'
  },
  educationId: {
    required: true,
    message: '申请人学历不能为空'
  },
}

// 新增代理2：校验规则2
export const descriptor2 = {

  level: {
    required: true,
    message: '授权等级不能为空'
  },
  name: {
    required: true,
    message: '申请人姓名不能为空',
    transform(value) {
      return value.trim()
    }
  },
  phone: [
    {
      required: true,
      message: '申请人手机号不能为空'
    },
    // {
    //   pattern: /^1[345789]\d{9}$/,
    //   message: '申请人手机号输入格式错误'
    // }
  ],
  gender: {
    required: true,
    message: '申请人性别不能为空'
  }
}

// // 新增代理：校验身份证照片规则
// export const descriptorWithPhoto = {

//   level: {
//     required: true,
//     message: '授权等级不能为空'
//   },
//   name: {
//     required: true,
//     message: '申请人姓名不能为空',
//     transform(value) {
//       return value.trim()
//     }
//   },
//   phone: [
//     {
//       required: true,
//       message: '申请人手机号不能为空'
//     },
//     // {
//     //   pattern: /^1[3456789]\d{9}$/,
//     //   message: '申请人手机号输入格式错误'
//     // }
//   ],
//   identity: {
//     required: true,
//     message: '申请人证件号码不能为空'
//   },
//   identityType: {
//     required: true,
//     message: '申请人证件照类型不能为空'
//   },
//   identityFrontMediaId: {
//     required: true,
//     message: '申请人证件正面照不能为空'
//   },
//   identityBackMediaId: {
//     required: true,
//     message: '申请人证件背面照不能为空'
//   },
//   wechat: [
//     {
//       required: true,
//       message: '申请人微信号不能为空',
//       transform(value) {
//         return value.trim()
//       }
//     },
//     {
//       pattern: /^(\w|-){5,40}$/,
//       message: '申请人微信号格式错误'
//     }
//   ],
//   gender: {
//     required: true,
//     message: '申请人性别不能为空'
//   },
//   regionId: {
//     required: true,
//     message: '申请人省市区不能为空'
//   },
//   professionId: {
//     required: true,
//     message: '申请人职业不能为空'
//   },
//   educationId: {
//     required: true,
//     message: '申请人学历不能为空'
//   },

//   images: [
//     {
//       type: 'array',
//       required: true,
//       message: '至少上传一张转账/汇款截图'
//     }
//   ]
// }
