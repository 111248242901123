/**
 * 邀请代理
 *
 */
import html2canvas from 'html2canvas';
import Qrcode from 'qrcode.vue';
import * as Service from '@/service/auth';
import { mapState } from 'vuex';
import { updateAppMessageShareData } from '@/utils/wx-promise';
import { getUrlParams } from '@/utils/common.js';

const shareImg = 'http://q8zhcro9n.bkt.clouddn.com/20200709193127.png';

export default {
    name: 'agent-invite',
    // 获取代理详情

    data() {
        const defaultVal = '--';
        return {
            val: '', //二维码的链接
            imgsrc: '',
            showSharePoster: false, //  是否显示分享海报
            toSharePoster: null, //  要分享海报的链接
            showShareChoices: false, //  是否显示选择分享方式
            showLinkTip: false, //  是否显示分享链接提示
            loadingPoster: false, //  生成海报加载中
        };
    },
    components: {
        Qrcode,
    },
    computed: {
        ...mapState({
            user: state => state.user.user, //我的信息
        }),

        shareTitle() {
            const {
                user: { name, gender },
            } = this;
            // const callName = gender === 0 ? '她' : '他';
            // return `${name}邀请您加入${callName}的战队`;
            return `${name}邀请您扫码下单`;
        },
        /**
         * 改成直接分享，因为某些原因配置不了后端api为js安全域名，所以分享的链接直接变成填写表单页面
         */
        shareUrl() {
            const { val } = this;
            if (val) {
                const base = location.href.slice(0, location.href.indexOf('#') + 1);
                const params = getUrlParams(decodeURIComponent(val));
                const { seniorId, brandId } = params;
                //  base64编码
                const redirectUrl = btoa(val);
                return `${base}/auth/add-agent?seniorId=${seniorId}&brandId=${brandId}`;
                // return `${base}/auth/redirect-agent-invite?seniorId=${seniorId}&brandId=${brandId}&redirectUrl=${redirectUrl}`;
                // return `${base}/auth/add-agent?seniorId=${seniorId}&brandId=${brandId}&wechatToken=${localStorage.getItem('wechatToken')}`;
            }
            return null;
        },
    },
    //似乎使用第三方插件的时候有延迟，所以修改为用异线程插入
    async created() {
        this.val = await Service.getQrcode();
        setTimeout(() => {
            this.imgsrc = document
                .getElementsByTagName('canvas')[0]
                .toDataURL()
                .replace('image/png', 'image/octet-stream;'); //获取二维码值，并修改响应头部。
        }, 0);

        this.updateShareDesc();
    },

    methods: {
        //  渲染分享海报
        async renderSharePoster() {
            if (this.toSharePoster) {
                return;
            }
            const scale = this.getScale();
            const container = document.querySelector('body');
            const canvas = await html2canvas(container, {
                scale,
                onclone: function onclone(clone) {
                    //  隐藏悬浮图标
                    const dragables = clone.querySelectorAll('.dragable-box');
                    dragables.forEach(cart => {
                        cart.style.display = 'none';
                    });
                    //  隐藏不必要的元素
                    const needHides = clone.querySelectorAll('.need-hide');
                    needHides.forEach(neddHide => {
                        neddHide.classList.add('hide');
                    });
                    //  替换文字
                    const specTexts = clone.querySelectorAll('.spec-p');
                    specTexts.forEach(specText => specText.classList.toggle('hide'));
                    //  替换图片
                    const specImgs = clone.querySelectorAll('.spec-step');
                    specImgs.forEach(specImg => specImg.classList.toggle('hide'));
                },
            });
            this.toSharePoster = canvas.toDataURL();
            // renderDom.classList.remove('gradient-bg');
        },

        // 通过 window.devicePixelRatio来获取或者重置设备像素比。
        getScale() {
            if (window.devicePixelRatio && window.devicePixelRatio > 1) {
                return window.devicePixelRatio;
            }
            return 1;
        },

        //  更新微信分享菜单
        updateShareDesc() {
            //  自定义微信分享
            updateAppMessageShareData({
                title: this.shareTitle,
                desc: '点击链接填写资料马上加入',
                link: this.shareUrl,
                imgUrl: shareImg,
            }).catch(err => {
                console.log('微信分享err===', err);
            });
        },

        //  显示邀请方式
        async onInvite() {
            //  显示海报加载中
            this.loadingPoster = true;
            await this.$nextTick();
            //  画海报
            await this.renderSharePoster();
            this.loadingPoster = false;
            this.showShareChoices = true;
        },

        //  取消分享
        onCancelShare() {
            this.showShareChoices = false;
        },

        // 链接分享
        onInveiteLink() {
            this.showShareChoices = false;
            this.showLinkTip = true;
        },

        //海报分享
        onInveitePoster() {
            this.showShareChoices = false;
            this.showSharePoster = true;
        },

        onSavePoster() {
            // 保存图片
        },
    },
};
