import { Dialog, Notify, Toast } from 'vant'
import { mapState } from 'vuex'
import schema from 'async-validator'
import { ZLHHR_LEVEL } from '@/constants/sgs.js'
import UploadImage from '@/components/common/upload-image/index.vue'
import { upgradeAgent, getUpgradeLevels, upgradeAlllevels, getallsystemslevel } from '@/service/auth/index'
export default {
  name: 'upgrade-agent',

  components: {
    UploadImage

  },

  data() {
    return {
      // 校验信息
      descriptor: {

        level: {
          required: true,
          message: '授权等级不能为空'
        },
        name: [
          {
            type: 'string',
            required: true,
            message: '代理姓名不能为空',
          },
          {
            whitespace: true,
            pattern: /^[^\s]*$/,
            message: "代理姓名不能包含空格"
          }
        ],


      },
      cannotUpgrade: false,//当前可否进行升级操作
      levelList: [],
      showProductPicker: false,
      showLevelPicker: false,
      system: '',//当前的等级
      //表单数据
      upgradeAgentForm: {
        productObj: {}
      },
    }
  },

  computed: {
    ...mapState({
      user: state => state.user.user,
    }),
  },

  methods: {
    //获取当前用户的等级id，暂时只有一个system，所以只取第一个
    //切换用户选择的品牌
    systemChange(target, value) {
      const secondList = this.systemList.find((element, index) => {
        this.levelList[0].index = index;//吧下标记录下来，后面就不用在遍历了
        return element.name == value[0]
      });
      const secondArray = [];
      //展示系统名字，默认去取第一个
      secondList.levels.forEach(element => { secondArray.push(element.name) });
      target.setColumnValues(1, secondArray);
    },
    // 获取可以使用的升级等级
    async getLevelList() {
      const { systemId, id } = this.$route.query;
      const { systems } = await getUpgradeLevels({ systemId, juniorId: id })
      // 改装数据为vant-popu使用
      this.changeDataForPopup(systems);
    },

    /**
     * 校验表单
     * 校验通过：返回true，否则返回false
     */
    validateForm() {
      const { upgradeAgentForm } = this
      let pass = true
      // 后面非水光生产品的时候，可以在descriptor.js中export非水光生产品需要的descriptor
      const validator = new schema(this.descriptor)
      validator.validate(upgradeAgentForm, (errors, fields) => {
        if (errors && errors.length) {
          pass = false
          this.$toast(errors[0].message)
        }
      })
      return pass
    },
    // 将数据转化为popup使用的数据
    changeDataForPopup(systems) {
      //数据为空的时候
      if (systems.length === 0) {
        this.cannotUpgrade = true
        Toast("当前无可操作的用户等级")
        return false;
      }
      this.cannotUpgrade = false
      // 遍历每一项，如果有为空的，则删除
      systems = systems.filter(ele => ele.levels.length > 0);
      const showSystemList = [{ values: [], index: 0, className: 'column1', key: '产品', defaultIndex: 0 }, { values: [], key: '等级', className: 'column2', defaultIndex: 0 }];
      this.systemList = systems;
      systems.forEach(system => {
        showSystemList[0].values.push(system.name);
      });
      //  只放第1个的进去，后面动态的去设置第二个的
      systems && systems[0].levels && systems[0].levels.forEach(level => {
        showSystemList[1].values.push(level.name);
      });
      this.levelList = showSystemList;

      if (!systems || systems.length <= 0) {
        this.showNoPermissionsWindow()
      }
    },
    // 升级成功
    upgradeAgentSuccess() {
      const {
        name,
        levelObj,
        phone
      } = this.upgradeAgentForm
      let underStrategy = false

      if (levelObj.rank > ZLHHR_LEVEL) {
        underStrategy = true
      }

      this.$router.replace({
        name: 'upgrade-agent-result',
        query: {
          upgradeId: this.upgradeId,
        }
      })
    },
    //升级信息确认
    showSureWindow() {
      const {
        id,
        name,
        level,
        levelObj,
      } = this.upgradeAgentForm;

      Dialog.confirm({
        title: '升级代理信息确认',
        messageAlign: 'left',
        message: `申请人: ${name}\n授权级别: ${level}\n`
      }).then(() => {
        // on confirm
        upgradeAgent({
          toLevelId: levelObj.id,
          juniorId: id,
          seniorId: this.user.id,
        }).then((e) => {
          const { upgradeId } = e;
          this.upgradeId = upgradeId;
          this.upgradeAgentSuccess()
        })
      });
    },
    /**
     * 提示不能升级
     */
    showNoPermissionsWindow() {
      Dialog.alert({
        title: '错误提示',
        message: `代理不可升级！原因: 该代理已经是您能授权的最高级别`
      }).then(() => {
        this.$router.back()
      })
    },
    onSubmit() {
      if (this.cannotUpgrade) {
        this.showNoPermissionsWindow();
        return;
      }
      const isOk = this.validateForm();
      if (isOk) {
        this.showSureWindow();
      }
    },
    //获取本人可升级列表
    async getUpgradeAlllevels() {
      const systems = await upgradeAlllevels({ juniorId: this.user.id });
      // 改装数据为vant-popu使用
      this.changeDataForPopup(systems);

    },
    onLevelPickerConfirm(value, index) {
      //品牌名，授权成功后要显示
      this.systemName = value[0];
      this.showLevelPicker = false
      this.upgradeAgentForm.level = value[0] + '-' + value[1]
      this.upgradeAgentForm.levelObj = this.systemList[this.levelList[0].index]["levels"].find(level => { return level.name == value[1] });
      console.log(this.upgradeAgentForm);
    },
  },


  async created() {
    const { name, id, phone } = this.$route.query;
    // 如果返回的id等于userid，则是为自己升级
    if (id == this.user.id) {
      this.getUpgradeAlllevels();

    }
    else { this.getLevelList() }
    this.upgradeAgentForm.name = `${name}/${phone}`;
    this.upgradeAgentForm.id = id;
    const system = await getallsystemslevel(id);
    this.system = `${system[0].sysname}-${system[0].levelname}`;
  }

}