/**
 * 我的代理
 */
import { Dialog } from 'vant';
import IndexTabbar from '@/components/common/index-tabbar/index.vue';
import AgentSearch from '@/components/auth/agent-search/index.vue';
import ContactService from '@/components/auth/contact-service/index.vue';
import EmptyList from '@/components/common/empty-list/index.vue';
import BrandPicker from '@/components/common/brand-picker/index.vue';
import Tabs from '@/components/other/tabs/index.vue';
import { getStartedAt, getEndedAt, getDate } from '@/utils/common';
import { mapState } from 'vuex';
import * as Service from '@/service/auth';
import { getSystems } from '@/service/auth/index';
import { ISSHILISHOU } from '@/utils/common.js';
export default {
    name: 'my-invitation',

    components: {
        IndexTabbar,
        AgentSearch,
        ContactService,
        EmptyList,
        Tabs,
        BrandPicker,
    },

    data() {
        return {
            refferAllJuniors: [], //我推荐的人的列表
            keyword: '', // 搜索关键字
            directReferrals: {}, //  直接推荐人，key为拼音首字母，value为对应字母开头的推荐人
            directReferralsTemp: {},
            indirectReferrals: {}, // 结构同directReferrals，是按照直属下级查看
            allNumReferrals: {},
            benYueNumReferrals: {},
            isSearchMode: false, // 搜索模式
            showBrandPicker: false,
            nav: [
                { name: '团队列表', icon: 'nav1-2.png', path: 'my-agents' },
                { name: '邀请列表', icon: 'nav2-1.png', path: 'my-invitation' },
            ],
            operations: [
                {
                    name: '邀请代理',
                    icon: require('@/assets/images/auth/icon1.png'),
                    path: 'agent-invite',
                },
                //  {
                //   name: '升级代理',
                //   icon: require('@/assets/images/auth/icon2.png'),
                //   path: 'upgrade-agent'
                // },
                {
                    name: '我的申请',
                    icon: require('@/assets/images/auth/icon3.png'),
                    path: 'my-application',
                },
                {
                    name: '我的审核',
                    icon: require('@/assets/images/auth/icon4.png'),
                    path: 'my-review',
                },
                {
                    name: '订单审核',
                    icon: require('@/assets/images/auth/icon5.png'),
                    path: 'order-review',
                },
            ],
            tabList: [
                {
                    name: '直接推荐(0)',
                    index: 0,
                    children: [
                        { name: '按昵称查看', index: '00' },
                        { name: '按月推查看', index: '01' },
                        { name: '按总推查看', index: '02' },
                    ],
                },
                { name: '间接推荐(0)', index: 1 },
            ],
            addInvitationsSum: null,
            isAlpha: true, // 是否显示字母
            systemType: { text: '实力瘦', id: 1 },
        };
    },

    computed: {
        ...mapState({
            user: state => state.user.user,
            brandId: status => status.global.brandId,
        }),
        // 是否展示我的团体
        showMyTeam() {
            return ISSHILISHOU();
        },
        showOperations() {
            const { operations } = this;
            return operations.filter(item => item.name != '新增代理');
        },

        // 下级经销商列表
        agents() {
            let agents = [];
            const { directReferrals } = this;
            if (directReferrals) {
                const values = Object.values(directReferrals);
                values.forEach(list => {
                    if (list && list.length) {
                        agents = agents.concat(list);
                    }
                });
            }
            return agents;
        },
    },

    watch: {
        $route: {
            handler: function(to, from) {
                // 从新增/升级代理回来，需要刷新列表，显示不需要新增/审计不需要审核的代理
                if (from.name !== 'agent-detail' && to.name === 'my-agents') {
                    this.init();
                }
            },
            // 深度观察监听
            deep: true,
        },
    },

    methods: {
        // 获取推荐人的信息
        async getseniorInfo() {
            const seniorInfo = await Service.getseniorInfo(this.user.id, this.brandId);
            // 推荐人为空白的时候不显示
            seniorInfo.length ? (this.agents = seniorInfo) : (this.agents = []);
            console.log(this.agents);
        },

        onDetail(agent) {
            // 如果是推荐人的话，不跳转页面
            if (this.user.valid != 1) {
                return;
            }
            this.$router.push({
                name: 'agent-detail',
                query: {
                    agentId: agent.id,
                    type: agent.type,
                    systemId: agent.systemId,
                },
            });
        },

        // tab nav 的下拉选项相应
        onTabNavItem(item) {
            let key = item.index;
            if (key === '00') {
                this.directReferrals = this.directReferralsTemp;
                this.isAlpha = true;
            }
            if (key === '01') {
                this.directReferrals = this.benYueNumReferrals;
                this.isAlpha = false;
            }
            if (key === '02') {
                this.directReferrals = this.allNumReferrals;
                this.isAlpha = false;
            }
        },

        // 事件分发
        async handleAction(e) {
            // 如果是二维码的话需要校验下可否邀请
            if (e.path == 'agent-invite') {
                if (!(await Service.judgeAuth())) {
                    Dialog.alert({
                        message: '您还没有授权，不能邀请下级',
                    });
                    return false;
                }
            }
            this.$router.push({
                name: e.path,
                query:
                    e.path === 'upgrade-agent'
                        ? {
                              id: this.user.id,
                              name: this.user.name,
                              // systemId: this.user.systemId,
                              phone: this.user.phone,
                          }
                        : null,
            });
        },
        // 清空关键字
        onDeleteKeyword() {
            this.keyword = '';
            this.$refs['keyword'].focus();
        },

        // 取消：取消搜索模式
        onCancel() {
            this.keyword = '';
            this.isSearchMode = false;
        },

        // 点击输入：进入搜索模式
        onFocus() {
            this.isSearchMode = true;
        },

        // 新增代理：跳转
        onAdd() {
            this.$router.push({
                name: 'add-agent',
            });
        },
        // 跳转到我的申请
        myApplication() {
            this.$router.push({
                name: 'my-application',
            });
        },
        // 二维码邀请
        agentInvite() {
            this.$router.push({
                name: 'agent-invite',
            });
        },
        // 升级代理：先搜索代理
        onUpgrade() {
            this.$refs['keyword'].focus();
        },

        async init() {
            const { brandId, user, systemType } = this;
            const { id: userId } = user;
            const juniorInfo =
                (await Service.getReferrer({
                    systemId: systemType.id,
                    userId: userId,
                    queryUserId: userId,
                    startTime: getStartedAt(new Date()),
                    endTime: getEndedAt(new Date()),
                })) || {};
            if (this.user.valid === 1) {
                this.directReferrals = juniorInfo.directList;
                this.indirectReferrals = juniorInfo.indirectList;
                // 构建和directReferrals一样的数据结构
                this.directReferralsTemp = juniorInfo.directList;
                this.allNumReferrals = { A: juniorInfo.allNumDirectList };
                this.benYueNumReferrals = { B: juniorInfo.benYueNumDirectList };
            } else {
                this.getSeniorInfo();
            }

            const addInvitations = await Service.getNewAddAgency({
                systemId: brandId,
                userId: userId,
                queryUserId: userId,
                startTime: getStartedAt(new Date()),
                endTime: getEndedAt(new Date()),
            });
            if (addInvitations) {
                this.addInvitationsSum = addInvitations.sum;
            }
        },

        // 跳转到团队列表
        handleSwitch() {
            this.$router.push({
                name: 'my-agents',
            });
        },

        // 新增推荐
        handleAddInvitation(type) {
            this.$router.push({
                name: 'add-invitation',
                query: {
                    type: type,
                },
            });
        },

        // 点击nav
        onNav(name) {
            this.$router.replace({ name });
        },

        // 选择品牌
        onConfirmBrandPicker(row) {
            this.showBrandPicker = false;
            this.systemType = row;
            this.init();
        },

        async getSystemsData() {
            const systemList = (await getSystems()) || [];
            let list = systemList.map(item => ({ text: item.name, id: item.id }));
            this.systemType = list[0];
        },
    },

    async created() {
        this.init();
        this.getSystemsData();
    },
};
