import { mapState } from 'vuex';
import { userSystemApply } from '@/service/auth/index';

export default {
    data() {
        return {
            nav: [
                { name: '新增', icon: 'ap1-1.png', icon2: 'ap1-2.png' },
                { name: '升级', icon: 'ap2-1.png', icon2: 'ap2-2.png' }
            ],
            curNav: 0,
            list: []
        }
    },
    computed: {
        ...mapState({
            userId: state => state.user.user.id
        }),
    },
    mounted() {
        this.getApplyList();
    },
    methods: {
        onNav(index) {
            this.curNav = index;
            this.getApplyList();
        },

        // 获取列表
        async getApplyList(){
            let { userId, curNav } = this;
            const params = {
                userId,
                type: curNav == 0? 'INCREASE':'UPGRADE'
            }
            const res = await userSystemApply(params)
            this.list = res
        }
    }
}