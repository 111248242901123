import { Toast } from 'vant'
import { mapState } from 'vuex'
import OverlaySuccessNotice from '@/components/common/overlay-success-notice/index.vue'
import { getAgentList } from '@/service/auth/index'

/*
 * @Author: yanjing.feng 
 * @Date: 2020-05-12 14:56:59 
 * @Last Modified by: yanjing.feng
 * @Last Modified time: 2020-05-15 11:29:07
 */
export default {
  name: 'agent-search-page',
  components: {
    OverlaySuccessNotice,
  },
  data() {
    return {
      keyword: '', //  关键词
      logoUrl: require('./../../../assets/images/security/logo@2x.png'),
      lifeUrl: require('./../../../assets/images/security/beauty-life@2x.png'),
      result: null,  //查询防伪码的结果
      loading: false,
      success: false,
    }
  },
  computed: {
    ...mapState({
      backgroundImage: state => state.permission.permission.login.backgroundImage
    }),
    error() { //  查询不到结果显示错误提示
      const { success, keyword, result } = this
      return !success && keyword && result && result.error
    }
  },
  watch: {
    keyword: function (newVal, oldVal) {  //  如果keyword被清空，那么重置搜索结果
      if (newVal != oldVal && !newVal) {
        this.onClear()
      }
    }
  },
  methods: {
    async onSubmit(e) {
      e.preventDefault()
      let { keyword } = this
      if (!keyword || !keyword.trim()) {
        Toast('请输入姓名/电话/微信号进行查询~')
        this.keyword = ''
        this.$refs.keyworkInput.focus()
        return
      }
      getAgentList({ userKeyword: keyword }).then(res => {
        if (res && res.length) {
          this.result = { agentList: res }
          this.$nextTick(() => {
            this.$refs.successDialog.show()
          })
        } else {
          this.result = { error: true }
        }
      })
    },
    onClear() {
      this.keyword = ''
      this.result = null
    }
  }
}