export const TabList = [
    {
        name: '添加代理',
        icon: require('@/assets/images/auth/icon1.png'),
        to: 'add-agent'
    },
    {
        name: '代理管理',
        icon: require('@/assets/images/auth/icon2-new.png'),
        to: 'my-agents',
    }, {
        name: '我的审核',
        icon: require('@/assets/images/auth/icon3.png'),
        to: 'my-review',
    },
    {
        name: '订单审核',
        icon: require('@/assets/images/auth/icon4.png'),
        to: 'order-review',
    }
];