export default {
  name: 'overlay-success-notice',
  props: {
    customClass: {
      type: String,
      default: null
    },
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    show () {
      this.visible = true
    },
    close () {
      this.visible = false
    },
    onClickContent(){
      this.$emit('click')
    }
  },
}
