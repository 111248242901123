import { mapState } from 'vuex';
import { Dialog, Toast } from 'vant';
import { getUpdateInformation, cancelInformation } from '@/service/auth/index';
/**
 * 增加代理
 */
export default {
    name: 'upgrade-agent-result',

    data() {
        return {
            agentList: null, //申请人信息/审核人信息
        };
    },
    // 监听,当路由发生变化的时候执行
    watch: {
        $route: {
            handler: function(val, oldVal) {
                this.getParams(val);
            },
            // 深度观察监听
            deep: true,
        },
    },

    computed: {
        //申请人信息
        applicant() {
            return [
                { name: '申请人', value: this.agentList ? this.agentList[0].userPOS.name : '' },
                { name: '当前等级', value: this.agentList ? this.agentList[0].fromLevelId : '' },
                { name: '申请等级', value: this.agentList ? this.agentList[0].toLevelId : '' },
                { name: '联系电话', value: this.agentList ? this.agentList[0].userPOS.phone : '' },
                { name: '微信号', value: this.agentList ? this.agentList[0].userPOS.wechat : '' },
            ];
        },
        //审核人信息
        auditor() {
            return [
                { name: '审核人上级', value: this.agentList ? this.agentList[1].name : '' },
                { name: '电话', value: this.agentList ? this.agentList[1].phone : '' },
                { name: '微信', value: this.agentList ? this.agentList[1].wechat : '' },
            ];
        },
        ...mapState({
            showHome: state => state.permission.permission.auth['apply-successed']['go-home'],
        }),
    },

    methods: {
        // 确定按钮，返回我的团体页面
        sure() {
            const { from } = this.$route.query;
            this.$router.replace({
                name: from,
            });
        },

        //撤回
        async cancel() {
            try {
                await Dialog.confirm({
                    message: '确定撤回该申请嘛？',
                });
                const data = await cancelInformation(this.$route.query.upgradeId);
                Toast(data);
            } catch (error) {
                console.log(error);
            }
        },
    },

    async created() {
        const { upgradeId } = this.$route.query;

        this.agentList = await getUpdateInformation(upgradeId);
    },
};

