import { getSystems } from '@/service/auth/index';

export default {
    props: {
        show: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            showPicker: false,
            loading: false,
            columns: [{ text: '实力瘦', id: 1 }],
        };
    },
    watch: {
        show(val) {
            this.showPicker = val;
        },
        showPicker(val) {
            if (!val) this.$emit('cancel');
        },
    },

    created() {
        this.getSystemsData();
    },

    methods: {
        // 点击取消
        onCancel() {
            this.$emit('cancel');
        },

        // 点击确定
        onConfirm(row) {
            this.$emit('confirm', row);
        },

        async getSystemsData() {
            this.loading = true;
            const systemList = (await getSystems()) || [];
            let list = systemList.map(item => ({ text: item.name, id: item.id }));
            this.loading = false;
            this.columns = list;
        },
    },
};
