/*
 * @Author: djm
 * @Date: 2020-07-06 11:09:11
 * @Description:
 */
/**
 * 个人资料（包括请求）
 */
export default {
  name: "avatar",

  props: {
    brandName: {
      required: false, //  授权品牌名称
      default: "",
    },
    userInfo: {
      required: true,
      type: Object,
      default: () => {
        return {
          name: "吴亦凡",
          level: "股东",
          avatar: "http://pics.sc.chinaz.com/files/pic/pic9/201509/apic14546.jpg",
        };
      },
    },
  },

  data() {
    return {
      defaultAvatr: require("./../../../assets/images/zpcommon/default-avatar.png"),
      defVal: "--",
    };
  },

  methods: {
    onClick() {
      this.$emit("click");
    },
  },
};
