<!--
 * @Author: fengyanjing
 * @Date: 2020-06-15 20:54:03
 * @Description:
 * @FilePath: /frontend/src/pages/auth/redirect-agent-invite/index.vue
-->
<template>
  <div class="redirect-agent-invite">跳转中～</div>
</template>

<script>
import { getUrlParams } from "@/utils/common.js";

export default {
  name: "redirect-agent-invite",
  mounted() {
    const { redirectUrl } = this.$route.query;
    // window.location = decodeURIComponent(redirectUrl);
    //  base64解码
    window.location = atob(redirectUrl)
  },
};
</script>

<style style="less" scoped>
.redirect-agent-invite {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
