import Collapse from '@/components/common/collapse/index.vue';
import EmptyList from '@/components/common/empty-list/index.vue';
// var pinyin = require("pinyin")

export default {
    name: 'tabs',

    components: {
        Collapse,
        EmptyList,
    },

    props: {
        // 标签列表
        tabList: {
            type: Array,
            default: () => [],
            required: true,
        },

        // Map1数据
        mapData1: {
            type: Object,
            default: () => ({}),
            required: false,
        },

        // Map2数据
        mapData2: {
            type: Object,
            default: () => ({}),
            required: false,
        },

        // Map3数据
        mapData3: {
            type: Object,
            default: () => ({}),
            required: false,
        },

        // list1数据
        listData1: {
            type: Array,
            default: () => [],
            required: false,
        },

        // list2数据
        listData2: {
            type: Object,
            default: () => ({}),
            required: false,
        },

        // 是否显示等级那里的升级字体
        updataText: {
            type: Boolean,
            default: false,
        },

        // 是否显示字母
        showAlpha: {
            type: Boolean,
            default: false,
        },

        // 暂时使用变量
        temp: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            dataMap1: {},
            dataMap2: {},
            dataList1: [],
            defaultAvatr: require('@/assets/images/zpcommon/default-avatar.png'),
            navTabPullDown: null,
            listPullDown: null, // 是否张开list item
            type: 0, // 查看类型。初始化为i全部经销商
            childType: '00', // 选项下面的子选择
        };
    },

    watch: {
        mapData1: {
            deep: false,
            // 按首字拼音分组
            handler() {
                const { mapData1, tabList } = this;
                if (!mapData1) {
                    return;
                }
                this.dataMap1 = this.getLettersMap(mapData1);
                var index = tabList[0].name.indexOf('(');
                if (index) {
                    this.tabList[0].name = `${tabList[0].name.substring(0, index)}(${this.dataMap1.len})`;
                }
            },
        },

        mapData2: {
            deep: false,
            // 按首字拼音分组
            handler() {
                const { mapData2, tabList } = this;
                if (!mapData2) {
                    return;
                }
                this.dataMap2 = this.getLettersMap(mapData2);
                var index = tabList[1].name.indexOf('(');
                if (index) {
                    this.tabList[1].name = `${tabList[1].name.substring(0, index)}(${this.dataMap2.len})`;
                }
            },
        },

        listData1: {
            deep: false,
            handler() {
                const { listData1 } = this;
                this.dataList1 = listData1;
                this.type = 0; // 回到第一个 tab 选项
            },
        },
    },

    methods: {
        // 导航切换
        toggleNav(key) {
            // 关掉导航下面子选项
            if (this.type !== key) {
                this.navTabPullDown = -1;
            }
            this.type = key;
            this.$emit('nav-click', key);
        },

        // 导航下面子选项切换
        toggleNavTab(key) {
            if (this.navTabPullDown === key) {
                this.navTabPullDown = -1;
                return;
            }
            this.navTabPullDown = key;
        },

        // 列表打开、关闭
        toggleSection(key) {
            if (this.listPullDown === key) {
                this.listPullDown = -1;
                return;
            }
            this.listPullDown = key;
        },

        onDetail(agent) {
            // this.$emit("item-click", agent);
        },

        getLettersMap(dataMap) {
            const letters = Object.keys(dataMap).sort();
            const specIndex = letters.findIndex(letter => letter === '#');
            if (specIndex >= 0) {
                const letter = letters.splice(specIndex, 1);
                if (letter && letter.length) {
                    letters.unshift(letter[0]);
                }
            }
            const showList = [];
            let len = 0;
            letters.forEach(key => {
                len += dataMap[key].length;
                showList.push({ name: key, list: dataMap[key] });
            });
            return {
                indexList: letters,
                len,
                showList,
            };
        },

        handleNavItem(item) {
            if (this.navTabPullDown === item.index) {
                this.navTabPullDown = -1;
                return;
            }
            this.navTabPullDown = item.index;
            this.childType = item.index;
            this.$emit('nav-item-click', item);
        },
    },
};
