/**
 * 上传图片组
 */
import { chooseImage, previewImage, uploadImage, getLocalImgData } from '@/utils/wx-promise'
export default {
  name: 'upload-identification',

  model: {
    prop: 'images',
    event: 'change'
  },
  props: {
    images: { // 传入的图片数组: Object
      type: Object,
      required: true,
      default: () => ({ back: null, font: null })
    }
  },

  data () {
    return {
      backSrc: require('@/assets/images/auth/identification-back.png'),
      fontSrc: require('@/assets/images/auth/identification-font.png'),
      localImages: {}, // 当前图片
    }
  },

  watch: {
    // 父 => 子
    images: {
      deep: true,
      immediate: true,
      handler () {
        this.localImages = this.images
      }
    },

    // 子 => 父
    localImages (val) {
      this.$emit('change', val)
    }
  },

  filters: {
    getURL (image) {
      if (!image) {
        return
      }
      return image.localData || image.localId || image.serverId || image.qiniuUrl || image
    }
  },

  methods: {
    /**
     * 预览图片
     */
    async onPreview (item) {
      await previewImage(item.localId || item, [item].map(item => item.localId || item.qiniuUrl || item))
    },

    /**
     * 点击添加
     */
    async onAdd (type) {
      const { localImages } = this
      //  只能选一张
      let newLocalIds = await chooseImage(1)
      let newImages = []
      for (const localId of newLocalIds) {
        let ret = { localId }
        if (window.__wxjs_is_wkwebview) {
          ret.localData = await getLocalImgData(localId)
        }
        ret.serverId = await uploadImage(localId)
        newImages.push(ret)
      }

      this.localImages = { ...localImages, [type]: newImages[0] }
    },

    /**
     * 删除1张图片
     */
    onDelete (type) {
      const { localImages } = this
      this.localImages = { ...localImages, [type]: null }
    }
  }
}