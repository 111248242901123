/**
 * 代理详情
 */
import * as Service from '@/service/auth'
import { mapState } from 'vuex'
import EmptyList from '@/components/common/empty-list/index.vue'

export default {
  name: 'my-application',
  components: {
    EmptyList
  },
  methods: {
    // 初始化,不改变tab的
    async init() {
      this.showList = [];
      this.offset = 0;
      this.finished = false;
      this.loading = false
      this.getUpdateList();
    },

    // 获取升级的列表
    async getUpdateList() {
      const { userId, tabListUpdate, limit, offset, active } = this;
      const params = {
        userId,
        state: tabListUpdate[active].value,
        offset,
        limit
      }
      const { rows } = await Service.getAgentUpdate(params);
      offset === 0 ? this.showList = rows : this.showList.push(...rows);
      this.loading = false;
      // 如果返回的长度为<limit，则说明是加载完毕了，如果不是，则说明可以继续加载
      if (rows.length < limit) {
        this.finished = true;
        // 如果返回的长度是0并且当前的下标页面时0，则说明一条数据都没有
      }
      else {
        this.finished = false;
      }
    },
    // 下拉加载的函数
    onLoad(e) {
      this.offset += this.limit;
      this.getUpdateList();
    },
    toDetail(id) {
      const { type, tabListIncrease, tabListUpdate, active } = this;
      sessionStorage.setItem("active", active);
      sessionStorage.setItem("type", type);
      this.$router.push({
        name: 'my-application-detail',
        query: {
          id, type, state: type === 0 ? tabListIncrease[active].value : tabListUpdate[active].value
        }
      })
    },

    // 刷新升级的
    refreshUpdate(index) {
      this.active = index;
      this.offset = 0;
      this.finished = false;
      this.showList = [];
      this.getUpdateList();
    },

  },

  data() {
    return {
      showList: [],
      finished: false,
      offset: 0,//页数，0开始
      limit: 10,//每页的调数
      loading: false,
      active: parseInt(sessionStorage.getItem("active")) || 0,

      tabListUpdate: [
        { name: '待上级审核', value: 0 },
        { name: '已同意', value: 20 },
        { name: '已拒绝', value: 21 }
      ],
      query: { // 页面参数
        agentId: null, // 当前代理ID
        isDirect: false, // 是否是直接下级
      },
      typeList:
        [{ name: '新增代理', index: 0, active: require("@/assets/images/auth/brand_active.png"), unactive: require("@/assets/images/auth/brand_unactive.png") }
          , { name: '升级代理', index: 1, active: require("@/assets/images/auth/agent_active.png"), unactive: require("@/assets/images/auth/agent_unactive.png") }],
      agent: {}, // 当前代理
      canUpgrade: false, // 能否给当前代理升级
      juniors: [], // 下级代理列表
      section: [],
      system: [],
    }
  },

  computed: {
    ...mapState({
      userId: state => state.user.user.id // 我的ID
    })
  },

  watch: {
    '$route': {
      handler(newRoute, oldRoute) {
        this.init()
      }
    },
  },

  created() {
    this.init()
  }
}
