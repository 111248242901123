import Avatar from '../../other/avatar/index.vue';
import MonthPicker from '../../rebate/month-picker/index.vue';

export default {
  name: 'invitation-header',
  components: {
    Avatar,
    MonthPicker
  },
  model: {
    prop: 'selectedDate',
    event: 'onSelectedDateChange'
  },
  props: {

    userInfo: {
      required: true,
      type: Object,
      default: () => {
        return {
          userName: '吴亦凡',
          level: '股东',
          avatar: 'http://pics.sc.chinaz.com/files/pic/pic9/201509/apic14546.jpg'
        }
      }
    },

    minDate: {
      required: false,
      default: () => new Date(2020, 3) // 最小日期：2020-04
    },

    selectedDate: { //  当前日期选择器选中的时间
      required: true,
      default: () => new Date()
    },

    title: {
      required: true,
      type: String,
    },

    num: {
      required: true,
      type: Number,
      default: () => 0
    }

  },
  methods: {
    changeSelectedDate (newVal) {
      this.$emit('onSelectedDateChange', newVal)
    }

  },
}