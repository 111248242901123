/**
 * 。。。算了，还是拆分成两个页面吧，，，，
 */
import * as Service from '@/service/auth'
import { mapState } from 'vuex'
import { previewImage } from '@/utils/wx-promise'
import { Dialog } from 'vant';

export default {
  name: 'my-application-detail',
  methods: {
    // 获取申请详情
    init() {
      const { id, type } = this.$route.query;
      this.type = type;
      if (type == 0) {
        this.getIncreaseDetail(id);
      }
      else {
        this.getUpdateDetail(id);
      }
    },
    // 获取新增的详情
    async getIncreaseDetail(id) {
      const { data } = await Service.getIncrementDetail(id);
      this.showInfo = data;
    },
    // 获取升级的详情
    async getUpdateDetail(id) {
      const { data } = await Service.getUpdateDetail(id);
      this.showInfo = data;
    },
    //展示图片，单张
    async previewImage(e) {
      await previewImage(e, [e]);
    },

  },

  data() {
    return {
      reason: '',
      showInfo: null,
      rejectShow: false
    }
  },

  computed: {
    ...mapState({
      userId: state => state.user.user.id // 我的ID
    })
  },
  // await previewImage(img[0],images)
  watch: {

    '$route': {
      handler(newRoute, oldRoute) {
        this.init()
      }
    },

  },

  created() {
    this.init()
  }
}
