/**
 * 代理详情
 */
import Collapse from '@/components/common/collapse/index.vue';
import * as Service from '@/service/auth';
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import { Dialog, Toast } from 'vant';
import { SHILISHOU_LEVEL_MAP } from '@/constants/rebate';
import { upgradeAgent } from '@/service/auth/index';

export default {
    name: 'agent-detail',

    components: {
        Collapse,
    },

    data() {
        const defaultVal = '--';
        return {
            defaultAvatr: require('@/assets/images/zpcommon/default-avatar.png'),
            toUpgradeInfo: {}, //将要升级的品牌id
            allMap: {}, //全部代理
            allJuniorsMap: {}, //直属代理
            sectionKey: null,
            defaultVal,
            systemList: [], //用户的升级列表
            query: {
                // 页面参数
                agentId: null, // 当前代理ID
                type: 0, // 是否是直接下级  1：是，0：否
            },
            typeList: [
                {
                    name: '授权品牌',
                    index: 0,
                    active: require('@/assets/images/auth/brand_active.png'),
                    unactive: require('@/assets/images/auth/brand_unactive.png'),
                },
                {
                    name: '下级代理',
                    index: 1,
                    active: require('@/assets/images/auth/agent_active.png'),
                    unactive: require('@/assets/images/auth/agent_unactive.png'),
                },
            ],
            agent: {}, // 当前代理
            levelJuniors: [],
            type: 0, //查看的类型，默认授权，0
            juniorsType: 0, //查看的类型
        };
    },

    computed: {
        ...mapState({
            userId: state => state.user.user.id, // 我的ID
        }),
        canUpgrade() {
            // 能否给当前代理升级
            return this.query.type == 1;
        },
    },

    watch: {
        $route: {
            handler(newRoute, oldRoute) {
                this.init();
            },
        },
        type: {
            async handler(newvalue) {
                // 第一次点击下级代理的时候去请求
                if (newvalue == 1 && this.levelJuniors.length == 0) {
                    // 如果数据都是空的，则去请求，毕竟都是空，就算他没下级，那么请求返回的时间也不多，可以忽略
                    await this.getJuniors();
                }
            },
        },
    },

    methods: {
        // 代理升级表单页面
        toUpgrade(systemId) {
            const { id, name, phone } = this.agent;
            this.$router.push({
                name: 'upgrade-agent',
                query: {
                    id,
                    name,
                    systemId,
                    phone,
                },
            });
        },

        // 确认升级
        confirmUpgradeBeforeClose(action, done) {
            const {
                toUpgradeInfo: { upLevelId, systemId },
            } = this;

            if (action === 'confirm') {
                //  请求升级
                // on confirm
                upgradeAgent({
                    systemId: systemId,
                    toLevelId: upLevelId,
                    juniorId: this.agent.id,
                    seniorId: this.userId,
                })
                    .then(e => {
                        const { upgradeId } = e;
                        this.upgradeId = upgradeId;
                        done();
                        this.$router.replace({
                            name: 'upgrade-agent-result',
                            query: {
                                upgradeId: this.upgradeId,
                                from: 'my-agents',
                            },
                        });
                    })
                    .catch(e => {
                        done();
                        console.error(e);
                    });
            } else {
                done();
            }
        },

        // 确认升级到上一级，只能向上升一级
        upgradeByStep(item) {
            const upLevelId = String(item.levelId);
            // const upLevelName = SHILISHOU_LEVEL_MAP[upLevelId];
            // if (!upLevelName) {
            //     Toast('暂无可升级的等级～');
            //     return;
            // }
            // this.toUpgradeInfo = { ...item, upLevelId, upLevelName };
            this.toUpgradeInfo = { ...item, upLevelId };
            Dialog.confirm({
                title: '确认升级',
                message: `确认要升级吗？`,
                beforeClose: this.confirmUpgradeBeforeClose,
            });
        },

        // （嵌套）查看详情
        onDetail(agent) {
            this.$router.push({
                name: 'agent-detail',
                query: {
                    agentId: agent.id,
                    type: 0, // 二级代理都不是直属关系，所以默认为0
                    seniorId: this.query.agentId, //上级id
                    systemId: this.query.systemId,
                },
            });
        },

        // 获取代理详情
        async getAgentDetail() {
            const { agentId } = this.query;
            this.agent = await Service.getAgentDetail(agentId);
        },

        // 查询代理是否能升级
        async getCanUpgrade() {
            let { agentId, seniorId, systemId } = this.query;
            seniorId = seniorId || this.userId;
            const { statues } = await Service.getSystemsList(agentId, seniorId, systemId);
            this.systemList = statues || [];
        },

        // 查询下级代理
        async getJuniors() {
            let { agentId, seniorId, systemId } = this.query;
            seniorId = seniorId || this.userId;
            const { allList, allJuniorsList, levelJuniors } = await Service.getJuniors(
                agentId,
                this.userId,
                seniorId,
                systemId
            );
            this.levelJuniors = levelJuniors;
            this.allMap = this.getLettersMap(allList);
            this.allJuniorsMap = this.getLettersMap(allJuniorsList);
        },

        async init() {
            // 初始化参数
            this.query = this.$route.query;
            this.type = 0; //初始化显示面板
            // 清空
            this.agent = {};
            this.canUpgrade = this.query.isDirect;
            this.levelJuniors = [];

            // 请求
            await Promise.all([this.getAgentDetail(), this.getCanUpgrade()]);
        },
        //切换显示
        toggleSection(toSectionKey) {
            if (this.sectionKey === toSectionKey) {
                this.sectionKey = -1;
                return;
            }
            this.sectionKey = toSectionKey;
        },
        getLettersMap(dataMap) {
            const letters = Object.keys(dataMap).sort();
            const specIndex = letters.findIndex(letter => letter === '#');
            if (specIndex >= 0) {
                const letter = letters.splice(specIndex, 1);
                if (letter && letter.length) {
                    letters.unshift(letter[0]);
                }
            }
            const showList = [];
            let len = 0;
            letters.forEach(key => {
                len += dataMap[key].length;
                showList.push({ name: key, list: dataMap[key] });
            });
            return {
                indexList: letters,
                len,
                showList,
            };
        },
    },

    mounted() {
        this.init();
    },
};
