import { mapState } from 'vuex' 
/**
 * 联系客服二维码：按钮 + 弹窗
 */
export default {
  name: 'contact-service',

  data () {
    return {
      visible: false
    }
  },

  computed: {
    ...mapState({
      qrcode: state => state.permission.permission.auth['apply-successed']['service-qrcode'],
    }),
  },

  methods: {
    showPopup () {
      this.visible = true
    },

    closePopup () {
      this.visible = false
    }
  }
}
