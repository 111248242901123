import Collapse from "@/components/common/collapse/index.vue";
import EmptyList from "@/components/common/empty-list/index.vue";
// var pinyin = require("pinyin")

export default {
  name: "agent-group",

  components: {
    Collapse,
    EmptyList,
  },

  props: {
    agentsMap: {
      // 经销商列表
      type: Object,
      default: () => ({}),
    },
    juniorsMap: {
      // 经销商列表
      type: Object,
      default: () => ({}),
    },
    section: {
      // 经销商列表（按等级分组）
      type: Array,
      default: () => ([]),
      required: false,
    },
    system: {
      //系统的
      type: Array,
      default: () => [],
      required: false,
    },
    showAlpha: {
      // 是否显示字母
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      defaultAvatr: require("@/assets/images/zpcommon/default-avatar.png"),
      sectionKey: null, // 当前选中分组的key
      // 修改为使用下标的方式来获取
      typeList: [
        { name: "全部代理(0)", index: 0 },
        { name: "按直属查看(0)", index: 2 },
        { name: "按等级查看", index: 1 },
      ],
      type: 0, // 查看类型。初始化为i全部经销商
      showList: [],
      indexList: [],
      //  按照直属查看的页面
      juniorsShowList: [],
      juniorsIndexList: [],
    };
  },

  watch: {
    section: {
      deep: false,
      handler() {
        // this.sectionKey = Object.keys(this.section)[0]
        //当section发生变换,即切换了新的经销商详情页面,将tab恢复到全部等级栏
        this.type = 0;
      },
    },
    system: {
      deep: false,
      handler() {
        // this.sectionKey = Object.keys(this.section)[0]
        //当section发生变换,即切换了新的经销商详情页面,将tab恢复到全部等级栏
        this.type = 0;
      },
    },

    juniorsMap: {
      deep: false,
      // 按首字拼音分组
      handler() {
        const { juniorsMap } = this;
        if (!juniorsMap) {
          return;
        }
        const dataMap = this.getLettersMap(juniorsMap);
        if (this.showAlpha) {
          this.juniorsIndexList = dataMap.indexList;
        }
        this.juniorsShowList = dataMap.showList;
        this.typeList[1].name = `按直属查看(${dataMap.len})`;
      },
    },

    agentsMap: {
      deep: false,
      // 按首字拼音分组
      handler() {
        const { agentsMap } = this;
        if (!agentsMap) {
          return;
        }
        const dataMap = this.getLettersMap(agentsMap);
        if (this.showAlpha) {
          this.indexList = dataMap.indexList;
        }
        this.showList = dataMap.showList;
        this.typeList[0].name = `全部代理(${dataMap.len})`;
      },
    },
  },

  methods: {
    toggleSection(toSectionKey) {
      if (this.sectionKey === toSectionKey) {
        this.sectionKey = -1;
        return;
      }
      this.sectionKey = toSectionKey;
    },

    onDetail(agent) {
      this.$emit("item-click", agent);
    },

    getLettersMap(dataMap) {
      const letters = Object.keys(dataMap).sort();
      const specIndex = letters.findIndex((letter) => letter === "#");
      if (specIndex >= 0) {
        const letter = letters.splice(specIndex, 1);
        if (letter && letter.length) {
          letters.unshift(letter[0]);
        }
      }
      const showList = [];
      let len = 0;
      letters.forEach((key) => {
        len += dataMap[key].length;
        showList.push({ name: key, list: dataMap[key] });
      });
      return {
        indexList: letters,
        len,
        showList,
      };
    },
  },
};
