/**
 * 我的代理
 */
import { Dialog } from 'vant';
import IndexTabbar from '@/components/common/index-tabbar/index.vue';
import AgentSearch from '@/components/auth/agent-search/index.vue';
import ContactService from '@/components/auth/contact-service/index.vue';
import EmptyList from '@/components/common/empty-list/index.vue';
import BrandPicker from '@/components/common/brand-picker/index.vue';
import AgentGroup from '@/components/auth/agent-group/index.vue';
import { getStartedAt, getEndedAt, getDate } from '@/utils/common';
import { mapState } from 'vuex';
import * as Service from '@/service/auth';
import { getSystems } from '@/service/auth/index';
import { ISSHILISHOU } from '@/utils/common.js';
export default {
    name: 'my-agents',

    components: {
        IndexTabbar,
        AgentSearch,
        ContactService,
        EmptyList,
        AgentGroup,
        BrandPicker,
    },

    data() {
        return {
            refferAllJuniors: [], //我推荐的人的列表
            keyword: '', // 搜索关键字
            section: [],
            agentsMap: {}, //  下级对象，key为拼音首字母，value为对应字母开头的下级经销商
            juniorsMap: {}, // 结构同agentsMap，是按照直属下级查看
            system: [], //按照产品
            isSearchMode: false, // 搜索模式
            showBrandPicker: false,
            nav: [
                { name: '团队列表', icon: 'nav1-1.png', path: 'my-agents' },
                { name: '邀请列表', icon: 'nav2-2.png', path: 'my-invitation' },
            ],
            tabList: [
                // {
                //   name: '邀请代理',
                //   icon: require('@/assets/images/auth/icon1.png'),
                //   path: 'agent-invite',
                // },
                //  {
                //   name: '升级代理',
                //   icon: require('@/assets/images/auth/icon2.png'),
                //   path: 'upgrade-agent'
                // },
                {
                    name: '添加代理',
                    icon: require('@/assets/images/auth/icon1.png'),
                    path: 'add-agent',
                },
                {
                    name: '我的申请',
                    icon: require('@/assets/images/auth/icon2.png'),
                    path: 'my-application',
                },
                {
                    name: '我的审核',
                    icon: require('@/assets/images/auth/icon3.png'),
                    path: 'my-review',
                },
                {
                    name: '订单审核',
                    icon: require('@/assets/images/auth/icon4.png'),
                    path: 'order-review',
                },
            ],
            addInvitationsSum: null,
            upgradeAgencySum: null,
            systemType: { text: '实力瘦', id: 1 },
        };
    },

    computed: {
        ...mapState({
            user: state => state.user.user,
            brandId: status => status.global.brandId,
            params: state => state.params.paramsList,
        }),

        // 是否展示我的团体
        showMyTeam() {
            return ISSHILISHOU();
        },

        showTabList() {
            const { tabList } = this;
            return tabList.filter(item => item.name != '新增代理');
        },

        // 下级经销商列表
        agents() {
            let agents = [];
            const { agentsMap } = this;
            if (agentsMap) {
                const values = Object.values(agentsMap);
                values.forEach(list => {
                    if (list && list.length) {
                        agents = agents.concat(list);
                    }
                });
            }
            return agents;
        },

        // 是否显示页面
        isShow() {
            const { params } = this;
            let visible = true;
            params.map(param => {
                if (param.name === '代理管理') {
                    if (param.value == 0) {
                        visible = false;
                    }
                }
            });
            return visible;
        },
    },

    watch: {
        $route: {
            handler: function(to, from) {
                // 从新增/升级代理回来，需要刷新列表，显示不需要新增/审计不需要审核的代理
                if (from.name !== 'agent-detail' && to.name === 'my-agents') {
                    this.init();
                }
            },
            // 深度观察监听
            deep: true,
        },
    },

    mounted() {
        if (this.isShow) this.init();
        this.getSystemsData();
    },

    methods: {
        // 获取推荐人的信息
        async getseniorInfo() {
            const seniorInfo = await Service.getseniorInfo(this.user.id, this.brandId);
            // 推荐人为空白的时候不显示
            seniorInfo.length ? (this.agents = seniorInfo) : (this.agents = []);
            console.log(this.agents);
        },
        onDetail(agent) {
            // 如果是推荐人的话，不跳转页面
            if (this.user.valid != 1) {
                return;
            }
            this.$router.push({
                name: 'agent-detail',
                query: {
                    agentId: agent.id,
                    type: agent.type,
                    systemId: this.systemType.id,
                },
            });
        },
        // 事件分发
        async handleAction(e) {
            // 如果是二维码的话需要校验下可否邀请
            if (e.path == 'agent-invite') {
                if (!(await Service.judgeAuth())) {
                    Dialog.alert({
                        message: '您还没有授权，不能邀请下级',
                    });
                    return false;
                }
            }
            this.$router.push({
                name: e.path,
                query:
                    e.path === 'upgrade-agent'
                        ? {
                              id: this.user.id,
                              name: this.user.name,
                              // systemId: this.user.systemId,
                              phone: this.user.phone,
                          }
                        : null,
            });
        },
        // 清空关键字
        onDeleteKeyword() {
            this.keyword = '';
            this.$refs['keyword'].focus();
        },

        // 取消：取消搜索模式
        onCancel() {
            this.keyword = '';
            this.isSearchMode = false;
        },

        // 点击输入：进入搜索模式
        onFocus() {
            this.isSearchMode = true;
        },

        // 新增代理：跳转
        onAdd() {
            this.$router.push({
                name: 'add-agent',
            });
        },
        // 跳转到我的申请
        myApplication() {
            this.$router.push({
                name: 'my-application',
            });
        },
        // 二维码邀请
        agentInvite() {
            this.$router.push({
                name: 'agent-invite',
            });
        },
        // 升级代理：先搜索代理
        onUpgrade() {
            this.$refs['keyword'].focus();
        },

        async init() {
            const { brandId, user, systemType } = this;
            const { id: userId } = user;
            const juniorInfo =
                (await Service.getJuniorsInfo({
                    queryUserId: userId,
                    userId,
                    seniorId: userId,
                    brandId,
                    systemId: systemType.id,
                })) || {};
            if (this.user.valid === 1) {
                this.agentsMap = juniorInfo.allList;
                this.section = juniorInfo.levelJuniors;
                this.juniorsMap = juniorInfo.allJuniorsList;
            } else {
                this.getSeniorInfo();
            }
            const addInvitations = await Service.getNewAddAgency({
                systemId: brandId,
                userId: userId,
                queryUserId: userId,
                startTime: getStartedAt(new Date()),
                endTime: getEndedAt(new Date()),
            });
            if (addInvitations) {
                this.addInvitationsSum = addInvitations.sum;
            }
            const upgradeAgency = await Service.getNewUpgradeAgency({
                systemId: brandId,
                userId: userId,
                queryUserId: userId,
                startTime: getStartedAt(new Date()),
                endTime: getEndedAt(new Date()),
            });
            if (upgradeAgency) {
                this.upgradeAgencySum = upgradeAgency.sum;
            }
        },

        // 跳转到邀请列表
        handleSwitch() {
            this.$router.push({
                name: 'my-invitation',
            });
        },

        // 新增推荐
        handleAddInvitation(type) {
            this.$router.push({
                name: 'add-invitation',
                query: {
                    type: type,
                },
            });
        },

        // 点击nav
        onNav(name) {
            this.$router.replace({ name });
        },

        // 选择品牌
        onConfirmBrandPicker(row) {
            this.showBrandPicker = false;
            this.systemType = row;
            this.init();
        },

        async getSystemsData() {
            const systemList = (await getSystems()) || [];
            let list = systemList.map(item => ({ text: item.name, id: item.id }));
            this.systemType = list[0];
        },
    },
};
