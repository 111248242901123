/**
 * 代理详情
 */
import * as Service from '@/service/auth';
import EmptyList from '@/components/common/empty-list/index.vue';
import { mapState } from 'vuex';
import ReviewDetail from '@/components/auth/review-detail/index.vue';

export default {
    name: 'my-review',
    components: { EmptyList, ReviewDetail },
    methods: {
        // 重设待审/初审
        resetType(typeIndex) {
            // type没变化的时候不做改变
            if (this.type === typeIndex) {
            } else {
                this.type = typeIndex;

                this.showList = [];
                this.offset = 0;
                this.finished = false;
                this.getData();
            }
        },
        // 切换顶部tab的
        changeTab(item) {
            // type没变化的时候不做改变
            if (this.tab.name === item.name) {
            } else {
                this.showList = [];
                this.tab = item;
                this.type = 0;
                this.offset = 0;
                this.finished = false;
                this.getData();
            }
        },
        //  同意一个申请
        async sure(item) {
            // 升级
            if (this.tab.value === 1) {
                await Service.upgradeapproveAgent(item.id);
            }
            // 新增
            else {
                await Service.approveAgent(item.id);
            }
            // 清空数据
            this.offset = 0;
            this.getData();
            this.showmaininfo = 1;
        },
        // 拒绝代理
        async rejectAgent({ item, text }) {
            // 升级
            if (this.tab.value === 1) {
                await Service.upgraderejectAgent({ id: item.id, reason: text.trim(), type: 1 });
            }
            // 新增
            else {
                await Service.rejectAgent({ id: item.id, reason: text.trim(), type: 1 });
            }
            // 清空数据
            this.offset = 0;
            this.getData();
            this.showmaininfo = 3;
        },
        // 打开弹窗
        toDetail(row) {
            this.showmaininfo = 0;
            // 已审的不需要显示弹窗
            this.showDetail = true;
            this.operarow = row;
        },
        // 关闭弹窗
        closeDetail(row) {
            this.showDetail = false;
        },

        // 获取列表
        async getData() {
            const { userId, limit, offset, tab, type } = this;
            const params = {
                userId,
                juniorId: userId,
                offset,
                limit,
            };
            let data;
            // 升级代理的
            if (this.tab.value === 1) {
                if (this.type === 0) {
                    params.state = '0,5';
                } else {
                    params.state = '23,22,21,20';
                }
                data = await Service.getReviewTheUpgrade(params);
                data.rows = data.all;
            }
            // 新增代理
            if (this.tab.value === 0) {
                // 待审的state为5，0
                if (this.type === 0) {
                    params.state = '0,5';
                } else {
                    params.state = '23,22,21,20';
                }
                data = await Service.getAgentReview(params);
            }

            let { rows } = data;
            //预防后台返回null
            rows = rows || [];
            offset === 0 ? (this.showList = rows) : this.showList.push(...rows);
            this.loading = false;
            // 如果返回的长度为<limit，则说明是加载完毕了，如果不是，则说明可以继续加载
            if (rows.length < limit) {
                this.finished = true;
                // 如果返回的长度是0并且当前的下标页面时0，则说明一条数据都没有
            } else {
                this.finished = false;
            }
        },
        // 下拉加载的函数
        onLoad(e) {
            this.offset += this.limit;
            this.getData();
        },
    },

    data() {
        const typeList = [
            {
                name: '新增代理',
                value: 0,
                active: require('./../../../assets/images/auth/selectd_unaudit.png'),
                unactive: require('./../../../assets/images/auth/unselectd_unaudit.png'),
            },
            {
                name: '升级代理',
                value: 1,
                active: require('./../../../assets/images/auth/selectd_audit.png'),
                unactive: require('./../../../assets/images/auth/unselectd_audit.png'),
            },
        ];
        return {
            showDetail: false, //是否展示个人信息的弹窗
            operarow: {}, //当前操作的列
            showList: [],
            finished: false,
            offset: 0, //页数，0开始
            limit: 10, //每页的调数
            loading: false,
            systemList: [], //用户的升级列表
            typeList,
            showmaininfo: 0, //控制子组件显示哪个弹窗
            tab: typeList[0], //当前操作的tab
            type: 0, //0代表的是待审，1代表的是已审核
            canUpgrade: false, // 能否给当前代理升级
        };
    },

    computed: {
        ...mapState({
            userId: state => state.user.user.id, // 我的ID
        }),
    },

    mounted() {
        this.getData();
    },
};
